import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import templateStore from "./templateStore";
import profileStore from "./profileStore";
import reportStore from "./reportStore";
import moment from "moment";
import { BookingStatusType, ProfileType, BookingType } from "../types/enum";
import ValidationHelper from "../helper/validation.js";
import CacheHelper from '../helper/cache.js'

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Hold",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act"
];

const BOOKING_STATUS_CLASS = [
    "New",
    "Declined",
    "Hold",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled-By-Act",
    "Cancelled-By-Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified",
];
const dstVariable = 120
class BookingRequestStore {
    @observable
    booking = {
        title: undefined,
        isBookingCreator: false,
        tempTitle: "",
        agentId: 0,
        actId: 0,
        privateHireId: 0,
        venueId: undefined,
        provider: "",
        venue: "",
        actName: "",
        venueName: "",
        venueContact: "",
        venueAddress: "",
        venuePhone: "",
        venueEmail: "",
        setNumber: 1,
        dress: "",
        gigFee: undefined,
        repeatType: 0,
        repeatCount: 1,
        agentCommissionFee: undefined,
        agentCommissionAmount: 0,
        requirements: "",
        notes: "",
        actFee: undefined,
        commissionType: 0,
        bookingDate: new Date("2018-03-21"),
        agentCommissionType: 0,
        dateStart: new Date(),
        dateEnd: new Date(),
        timeStart: new Date(),
        timeEnd: new Date(),
        loadInStart: undefined,
        loadInEnd: undefined,
        soundCheckStart: undefined,
        soundCheckEnd: undefined,
        isAdmin: false,
        myLineup: {},
        setlists: [],
        venueContract: "",
        agentContract: "",
        bookingRequests: [],
        createdBy: undefined,
        ticketUrl: "",
        sharePermission: true,
        shareUpdate: true,
        displayGig: false,
        immediateInvoice: false,
        isApprovalSent: false,
        extraGigFee: 0,
        extraGigFeeApprovalById: "",
        extraGigFeeApprovalAt: "",
        isApproved: false,
        clientFirstName: "",
        clientLastName: "",
        clientAddress: "",
        clientEmail: "",
        clientPhone: "",
        clientContract: "",
        addToContact: false,
        depositAmount: undefined,
        depositDue: 0,
        depositDueDate: moment().endOf("day"),
        balanceDue: 0,
        balanceDueDate: moment().endOf("day"),
        createInvoice: true,
        independentVenueId: undefined,
        finalize: false,
        type: 0,
        clientCompanyName: "",
        actType: undefined,
        actLineup: undefined,
        saveAsDraft: false,
        notifyAct: false,
        notifyClient: false,
        notifyAgent: false,
        saveNotesAsTemplate: false,
        saveRequirementsAsTemplate: false,
        signed: false,
        signedById: undefined,
        signedBy: undefined,
        signedAt: undefined,
        forceAct: false,
        purchaseOrderCode: undefined,
        isPrivateHire: false
    };

    @observable  offset = moment(new Date()).utcOffset()
    @observable genres= "";
    @observable vibes= "";
    @observable isPrivateHire= false;
    @observable privateHireInitialData = undefined;
    @observable bookingDetail = this.booking;
    // @observable bookingDetail = Object.assign({}, ...this.booking);
    @observable isActLoaded = false
    @observable actProfile = {};
    @observable venueProfile = {};
    @observable actId = undefined;
    @observable venueId = undefined;
    @observable myBooking = [];
    @observable myActBooking = [];
    @observable myCalendarActBooking = [];

    @observable drafts = [];
    @observable newRequests = [];
    @observable requests = [];
    @observable upcomings = [];
    @observable socials = [];
    @observable completed = [];
    @observable cancelled = [];
    @observable allGigs = [];
    @observable relatedBookings = [];
    @observable bookingCreators = [];
    
    @observable isLoading = false;
    @observable isSaving = false;
    @observable isLoadingVenue = false;
    @observable isLoadingAct = false;
    @observable isReadonly = true;
    @observable filteredData = {
        drafts: null,
        requests: null,
        upcomings: null,
        completed: null,
        cancelled: null,
        allGigs: null,
        socials: null
    }

    @observable error = undefined;

    @observable cancellationReason = undefined;

    @observable privateBookingHeading = "Entertainer Booking";

    @observable bookingHeading = "Edit Booking";

    @observable tempBookingTitle = "";

    @observable bookableActs = [];
    @observable embedUpcomingGigs = [];

    @observable bookingClientEmail = {
        clientInvoice: [],
        clientEmail: undefined,
        clientContract: undefined
    };
    @observable showMoreAll = false
    @observable showMoreUpcoming = false
    @observable showMoreCancelled = false
    @observable showMoreCompleted = false
    @observable showMoreRequest = false
    @observable showMoreSocial = false
    @observable draftPage = 1
    @observable upcomingPage = 1
    @observable completedPage = 1
    @observable cancelledPage = 1
    @observable allPage = 1
    
    
    @action 
    setIsPrivateHire(value) {
        this.isPrivateHire = value
    }
    @action 
    setPrivateHireInitialData(data) {
        this.privateHireInitialData = data
    }
    @action
    setIsActLoaded(state) {
        this.isActLoaded = state
    }
    @action
    setPrivateBookingHeading() {
        // let createdByAgent = this.booking.createdBy?.type === ProfileType.Agent.ordinal;
        // let privateBookingTitle = createdByAgent ? "Agent Private Booking" : "Act Booking/Invoice";
        // if (this.booking && this.booking.title) {
        //     privateBookingTitle += ` - ${this.booking.title}`;
        // } else {
        //     privateBookingTitle += ` - ${this.booking.tempTitle}`;
        // }

        // privateBookingTitle += ` ${moment(this.booking.dateStart).format("ddd, DD-MM-YYYY")} ${moment(this.booking.timeStart, "HH:mm").format(
        //     "h.mma"
        // )} - ${moment(this.booking.timeEnd, "HH:mm").format("h.mma")}`;

        // this.privateBookingHeading = privateBookingTitle;
        this.setPrivateBookingHeadingFromCalendar(moment(new Date()));
    }

    @action
    setBookingHeading() {
        var bookingTitle = "";

        let startTime;
        let endtTime;
        let date;
        let bookingCreatorType;

        if (this.booking.id) {
            date = this.booking.dateStart;
            startTime = this.booking.timeStart;
            endtTime = this.booking.timeEnd;
            bookingCreatorType = this.booking.createdBy?.type;
        }
        else {
            date = this.booking.dateStart ? this.booking.dateStart : moment();
            const reminder = 30 - (moment().minute() % 30);
            startTime = moment().add(reminder, 'minutes')
            endtTime = moment(startTime).add(3, "h");
            const currentProfile = profileStore.getCurrentProfile();
            bookingCreatorType = currentProfile?.type;
        }

        if (bookingCreatorType === ProfileType.Musician.ordinal) {
            bookingTitle = `Entertainer Booking - `;
        }

        bookingTitle += `${moment(date).format("ddd, DD-MM-YYYY")} ${moment(startTime, "HH:mm").format(
            "h.mma"
        )} - ${moment(endtTime, "HH:mm").format("h.mma")}`;
        this.bookingHeading = bookingTitle;
        return bookingTitle
    }

    @action
    setPrivateBookingHeadingFromCalendar(date, isNew) {
        var date = this.booking.dateStart;
        let createdByAgent = this.booking.createdBy?.type === ProfileType.Agent.ordinal;
        let privateBookingTitle = createdByAgent ? "Agent Private Booking" : "Act Booking/Invoice";
        let startTime
        let endtTime
        if (isNew) {
            const reminder = 30 - (moment().minute() % 30);
            startTime = moment().add(reminder, 'minutes')
            endtTime = moment(startTime).add(3, "h");
        } else {
            startTime = this.booking.timeStart
            endtTime = this.booking.timeEnd
        }
        if (this.booking && this.booking.title) {
            privateBookingTitle += ` - ${this.booking.title}`;
        } else {
            privateBookingTitle += ` - ${this.booking.tempTitle}`;
        }

        privateBookingTitle += ` ${moment(date).format("ddd, DD-MM-YYYY")} ${moment(startTime, "HH:mm").format("h.mma")} - ${moment(endtTime, "HH:mm").format("h.mma")}`;

        this.privateBookingHeading = privateBookingTitle;
    }

    @action
    setTempBookingTitle() {
        this.tempBookingTitle = this.booking.tempTitle;
    }

    @action
    setVenueId(venueId) {
        this.venueId = venueId
    }

    @action
    setActId(actId) {
        if (actId !== "undefined")
            this.actId = actId
    }

    @action
    setCancellationReason(value) {
        this.cancellationReason = value;
    }

    @action
    addError(fieldKey, errorMessage) {
        if (!this.error) {
            this.error = [];
        }
        this.error[fieldKey] = errorMessage;
    }

    @action
    removeError(fieldKey) {
        if (typeof this.error !== "undefined" && typeof this.error[fieldKey] !== "undefined") {
            delete this.error[fieldKey];
        }
    }

    @action
    addErrors(fieldKeys, errorMessages) {
        fieldKeys.forEach((fieldKey, index) => {
            this.addError(fieldKey, errorMessages);
        });
    }

    validateBooking() {
        const currentProfile = profileStore.getCurrentProfile();
        this.error = undefined;
        /* eslint-disable no-useless-escape*/
        let urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
        /* eslint-enable no-useless-escape*/
        if (this.booking) {
            if (
                (!this.booking.title || (this.booking.title && this.booking.title.length === 0)) &&
                (!this.booking.tempTitle || (this.booking.tempTitle && this.booking.tempTitle.length === 0))
            ) {
                this.addError("title", "Event title is required!");
            }
            if ((!this.booking.venueId || this.booking.venueId === 0) && currentProfile?.type === ProfileType.Staff.ordinal) {
                this.addError("venue", "Venue is required!");
            }
            if (currentProfile?.type !== ProfileType.Musician.ordinal && (!this.booking.actId || this.booking.actId === 0) && !this.booking.saveAsDraft) {
                this.addError("act", "Act is required!");
            }
            if (
                (!this.booking.agentId || this.booking.agentId === 0) &&
                currentProfile?.type === ProfileType.Agent.ordinal &&
                !this.booking.saveAsDraft
            ) {
                this.addError("agent", "Agent is required!");
            }
            // if (!this.booking.gigFee || this.booking.gigFee === 0) {
            //     this.addError("gigFee", "Gig Fee is required!");
            // }
            // if ((!this.booking.agentCommissionFee || this.booking.agentCommissionFee === 0) && currentProfile?.type === ProfileType.Agent.ordinal) {
            //     this.addError("agentCommissionFee", "Agent Commission Fee is required!");
            // }
            // if (!this.booking.actFee || this.booking.actFee <= 0) {
            //     this.addError("actFee", "Act Fee must greater than zero!");
            // }
            // if (currentProfile?.type == ProfileType.Staff.ordinal && this.booking.actFee && this.booking.actFee <= 0) {
            //  
            //     this.addError("actFee", "Act Fee must greater than zero!");
            // }
            if (!urlRegex.test(this.booking.ticketUrl) && this.booking.ticketUrl && this.booking.ticketUrl.length > 0) {
                this.addError("ticketUrl", "Invalid Url");
            }
        }

        return this.error === undefined;
    }

    validatePrivateBooking() {
        const currentProfile = profileStore.getCurrentProfile();
        this.error = undefined;
        /* eslint-disable no-useless-escape*/
        // let urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
        // var emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        /* eslint-enable no-useless-escape*/
        if (this.booking) {
            if (
                (!this.booking.title || (this.booking.title && this.booking.title.length === 0)) &&
                (!this.booking.tempTitle || (this.booking.tempTitle && this.booking.tempTitle.length === 0))
            ) {
                this.addError("title", "Event title is required!");
            }
            if (
                this.booking.venueName === "" &&
                (!this.booking.venueId || this.booking.venueId === 0) &&
                currentProfile?.type === ProfileType.Staff.ordinal
            ) {
                this.addError("venue", "Venue is required!");
            }
            // if (!this.booking.actId || this.booking.actId === 0) {
            //     this.addError("act", "Act is required!");
            // }
            // /*if(  (!this.booking.agentId || this.booking.agentId === 0) && currentProfile?.type === ProfileType.Agent.ordinal){
            //     this.addError('agent', 'Agent is required!');
            // }*/
            // if (!this.booking.gigFee || this.booking.gigFee === 0) {
            //     this.addError("gigFee", "Gig Fee is required!");
            // }
            // if ((!this.booking.agentCommissionFee || this.booking.agentCommissionFee === 0) && currentProfile?.type === ProfileType.Agent.ordinal) {
            //     this.addError("agentCommissionFee", "Agent Commission Fee is required!");
            // }
            // if (!this.booking.actFee || this.booking.actFee <= 0) {
            //     this.addError("actFee", "Act Fee must greater than zero!");
            // }
            // if (!this.booking.clientFirstName || this.booking.clientFirstName === "") {
            //     this.addError("clientFirstName", "Contact First Name is required!");
            // }
            // if (!this.booking.clientLastName || this.booking.clientLastName === "") {
            //     this.addError("clientLastName", "Contact Last Name is required!");
            // }
            // if (!this.booking.clientAddress || this.booking.clientAddress === "") {
            //     this.addError("clientAddress", "Contact Address is required!");
            // }
            // if (!this.booking.clientPhone || this.booking.clientPhone === "") {
            //     this.addError("clientPhone", "Contact Phone is required!");
            // }
            // if (!this.booking.clientEmail || this.booking.clientEmail === "") {
            //     this.addError("clientEmail", "Contact Email is required!");
            // }
            // if (this.booking.gigFee && this.booking.depositAmount && parseFloat(this.booking.gigFee) < parseFloat(this.booking.depositAmount)) {
            //     this.addError("balance", "Balance must greater than zero!");
            // }
            // if (!emailRegex.test(this.booking.clientEmail) && this.booking.clientEmail && this.booking.clientEmail.length > 0) {
            //     this.addError("clientEmail", "Invalid email format");
            // }
            // if (!urlRegex.test(this.booking.ticketUrl) && this.booking.ticketUrl && this.booking.ticketUrl.length > 0) {
            //     this.addError("ticketUrl", "Invalid url");
            // }
        }

        return this.error === undefined;
    }

    @action
    resetRequestsUpcomingCompleted = () => {
        this.requests = [];
        this.upcomings = [];
        this.completed = [];
        this.cancelled = [];
        this.allGigs = [];
    };

    @action
    setBookingsCount = (type, data) => {
        let currentCount = this.filteredData;
        currentCount[type] = data;
        this.filteredData = currentCount;
    }

    @action
    setBooking(prop, value) {
       
        this.error = undefined;
        // let target = {}
        // target[prop] = value
        // this.booking = Object.assign(this.booking, target);
        // console.log("set booking", prop, value);
        // console.log(this.error)
        this.booking[prop] = value;
    }

    @action
    setGenres(value) {
        this.genres = value;
    }

    @action
    setVibes(value) {
        this.vibes = value;
    }

    @action
    changeBookingRequests(value) {
        const currentProfile = profileStore.getCurrentProfile();
        if (this.booking.bookingRequests) {
            var newBookingRequests = [];

            var ids = value.split(",");

            ids.forEach(id => {
                var filtered = this.booking.bookingRequests.filter(bookingRequest => bookingRequest.actId === id);

                if (filtered.length === 0) {
                    newBookingRequests.push({
                        actId: id,
                        bookingId: this.booking.id,
                        createdById: currentProfile?.id
                    });
                } else {
                    newBookingRequests = newBookingRequests.concat(filtered);
                }
            });

            this.booking.bookingRequests = newBookingRequests;
        }
    }

    @action
    setProfileIds(actId, venueId) {
        if (actId !== "undefined")
            this.actId = actId;
        this.venueId = venueId;
    }

    @action
    load(id, bookingRequestId) {
        this.isLoading = true;
        const currentProfile = profileStore.getCurrentProfile();
        return agent.Booking.getDetail(id, bookingRequestId, currentProfile?.id)
            .then(
                action(booking => {
                    if (booking.extraGigFee) {
                        booking.isApprovalSent = true;
                    }
                    
                    
                    var dateStart = moment(booking.dateStart)
                    var dateEnd = moment(booking.dateEnd)
                    booking.dateStart = dateStart.subtract((parseFloat(booking.utcOffset) + dateStart.utcOffset()),'minute')
                    booking.dateEnd = dateEnd.subtract(parseFloat(booking.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(booking.dateStart)){
                        booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                        booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                    }
                    this.booking = booking;
                    let tempTitle = this.getBookingTitle(this.booking); // this.booking.act.actName + "@" + (this.booking.venue ? this.booking.venue.profileName : this.booking.venueName);
                    this.booking.tempTitle = tempTitle;

                    this.booking.actType = this.booking.act ? this.booking.act.actTypeId : undefined;
                    this.booking.actLineup = this.booking.act ? this.booking.act.lineUpCount : undefined;
                    this.booking.repeatCount = this.booking.repeatCount ? this.booking.repeatCount : 1;
                    this.booking.provider = booking?.createdBy?.type === ProfileType.Agent.ordinal && booking.createdBy.profileName ? booking?.createdBy?.profileName : booking?.provider ? booking.provider : booking?.createdBy?.firstName + " " + booking?.createdBy?.lastName;
                    if (booking.bookingVibes) {
                        this.vibes = booking.bookingVibes.map(vibe => vibe.vibeId).join();
                    }
                    if (booking.bookingGenres) {
                        this.genres = booking.bookingGenres.map(genre => genre.genreId).join();
                    }
                    this.setTempBookingTitle();
                    this.setPrivateBookingHeading();
                    return this.booking;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    getClientEmailPreview(id) {
        this.isLoading = true;
        return agent.Booking.getClientEmailPreview(id)
            .then(
                action(response => {
                    this.bookingClientEmail = response;
                    return this.bookingClientEmail;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }
    @action
    sendClientEmail(id, data) {
        this.isLoading = true;
        return agent.Booking.sendClientEmail(id, data)
            .then(
                action(response => {
                    return this.load(this.booking.id).then(res => {
                        return response;
                    })
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    loadGigsRequests(profileId, page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId, actId, creatorId} = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        return agent.Booking.loadRequestsGigs(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId, actId, page, creatorId).then(
            action(result => {
                var res = _.uniqBy(result.bookings, "id");
                this.showMoreRequest = result.isMore
                for (let gig of res) {
                    var dateStart = moment(gig.dateStart)
                    var dateEnd = moment(gig.dateEnd)
                    gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                    gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(gig.dateStart)){
                        gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                        gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                if (currentProfile?.type === ProfileType.Musician.ordinal) {
                    res = res.filter(x => !((!x.isAdmin && !x.myLineup && !x.bookingRequestId))); // filtered out rosters but excluded as lineups for musician;
                }
                this.bookingCreators = _.uniqBy(this.bookingCreators.concat(res.map(x=>x.createdBy)), "id")

                this.requests = this.requests.concat(res);
                this.drafts = this.requests.filter(x => x.saveAsDraft || x.status === BookingStatusType.Draft.ordinal);
                this.newRequests = this.requests.filter(x => !x.saveAsDraft && (x.notifyAct || x.notifyAgent || x.notifyClient) && x.status !== BookingStatusType.Draft.ordinal);
                
                return this.requests;
            })
        );
    }

    @action
    filterDeleteRequest(bookingId){
        this.drafts = this.drafts.filter(x => x.id != bookingId);
        this.newRequests = this.newRequests.filter(x => x.id != bookingId);
        this.requests = this.requests.filter(x => x.id != bookingId);;
    }

    @action
    loadUpcomingGigsPublic(profileId) {
        return agent.Booking.loadUpcomingGigsPublic(profileId).then(
            action(async res => {
           for (let gig of res) {
            var dateStart = moment(gig.dateStart)
            var dateEnd = moment(gig.dateEnd)
            gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
            gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
        
                    var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(gig.dateStart)){
                        gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                        gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                this.upcomings = res

                return this.upcomings
            })
        )
    }

    @action
    loadSocial(profileId, page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId, actId, createdId } = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        return agent.Booking.loadSocial(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId, actId, page, createdId).then(
            action(async result => {
                var temp = result.bookings
                
                
                this.showMoreSocial = result.isMore
                for (let gig of temp) {
                    await profileStore.mapAdditionalProfileImageAndBackgroundImages(gig.act);
                    await profileStore.mapAdditionalProfileImageAndBackgroundImages(gig.venue);
                }

             

                runInAction(() => {
                    this.bookingCreators = _.uniqBy(this.bookingCreators.concat(temp.map(x=>x.createdBy)), "id")

                    var res = this.socials.concat(temp)
                    if (currentProfile?.type === ProfileType.Musician.ordinal) {
                        this.socials = res.filter(x => !(!x.isAdmin && !x.myLineup)); // filtered out rosters but excluded as lineups for musician;
                    } else {
                        this.socials = res;
                    }
                })
                return this.socials;
            })
        );
    }

    @action
    loadUpcomingGigs(profileId, page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId, actId, createdId } = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        return agent.Booking.loadUpcomingsGigs(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId, actId, page, createdId).then(
            action(async result => {
                var temp = result.bookings
                
                
                this.showMoreUpcoming = result.isMore
                for (let gig of temp) {
                    await profileStore.mapAdditionalProfileImageAndBackgroundImages(gig.act);
                    await profileStore.mapAdditionalProfileImageAndBackgroundImages(gig.venue);
                    var dateStart = moment(gig.dateStart)
                    var dateEnd = moment(gig.dateEnd)
                    gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                    gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(gig.dateStart)){
                        gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                        gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                
             

                runInAction(() => {
                    this.bookingCreators = _.uniqBy(this.bookingCreators.concat(temp.map(x=>x.createdBy)), "id")

                    var res = this.upcomings.concat(temp)
                   
                   
                    if (currentProfile?.type === ProfileType.Musician.ordinal) {
                        this.upcomings = res.filter(x => !(!x.isAdmin && !x.myLineup)); // filtered out rosters but excluded as lineups for musician;
                    } else {
                        this.upcomings = res;
                    }
                })
                return this.upcomings;
            })
        );
    }

    @action
    handleSendReminder(bookingId) {
        var data = {
            bookingId :bookingId
        }
        return agent.Booking.sendReminder(data).then(
            action(res => {
                return;
            })
        );
    }

    @action
    handleSendReminderRequest(bookingId) {
        var data = {
            bookingId :bookingId
        }
        return agent.Booking.sendReminderRequest(data).then(
            action(res => {
                return;
            })
        );
    }
    
    @action
    loadUpcomingGigsWithoutAdhoc(profileId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId } = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        this.upcomings = [];

        return agent.Booking.loadUpcomingsGigsWithoutAdhoc(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId).then(
            action(res => {
                for (let gig of res?.bookings) {
                    var dateStart = moment(gig.dateStart)
                    var dateEnd = moment(gig.dateEnd)
                    gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                    gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(gig.dateStart)){
                        gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                        gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                this.upcomings = res.bookings;
                return this.upcomings;
            })
        );
    }
    

    @action
    loadEntertainmentUpcomingNextEvent(profileId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }
        return agent.Booking.loadUpcomingGigsNextEvent(profileId).then(
            action(async response => {
                if (response && response.length > 0 && response !== undefined) {
                    var mapped = [];
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i]
                        var dateStart = moment(gig.dateStart)
                        var dateEnd = moment(gig.dateEnd)
                        gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                        gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                    
                        var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                        if(dstChecking.isAfter(gig.dateStart)){
                            gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                            gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                        }
                        await profileStore.mapAdditionalProfileDataAsync(data.act);
                        mapped.push(data)
                    }
                    return mapped;
                } else {
                    return [];
                }
            })
        )
    }

    @action
    loadEntertainmentUpcomingNext4Event(profileId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }
        return agent.Booking.loadUpcomingGigs4NextEvent(profileId).then(
            action(async response => {
                if (response && response.length > 0 && response !== undefined) {
                    var mapped = [];
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i]
                        var dateStart = moment(data.dateStart)
                        var dateEnd = moment(data.dateEnd)
                        data.dateStart = dateStart.subtract((parseFloat(data.utcOffset) + dateStart.utcOffset()),'minute')
                        data.dateEnd = dateEnd.subtract(parseFloat(data.utcOffset) + dateEnd.utcOffset(),'minute'); 
            
                        var dstChecking = moment(data.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                        if(dstChecking.isAfter(data.dateStart)){
                            data.dateStart = data.dateStart.subtract(dstVariable,"minute")
                            data.dateEnd = data.dateEnd.subtract(dstVariable,"minute")
                        }
                        await profileStore.mapAdditionalProfileDataAsync(data.act);
                        mapped.push(data)
                    }
                    return mapped;
                } else {
                    return [];
                }
            })
        )
    }
    @action
    loadEntertainmentUpcomingGigsWithoutAdhoc(profileId, includeGenre, forVenueProfile) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate } = reportStore;

        let startDateParam = startDate ? moment(startDate).format("MM-DD-YYYY") : undefined;
        let endDateParam = endDate ? moment(endDate).format("MM-DD-YYYY") : undefined;

        if (forVenueProfile) {
            startDateParam = moment().format("MM-DD-YYYY")
        }

        if (includeGenre) {
            return agent.Booking.loadEntertainmentUpcomingsGigsWithoutAdhocIncludeGenre(profileId, startDateParam, endDateParam).then(
                action(async response => {
                    if (response && response.length > 0 && response !== undefined) {
                        var mapped = [];
                        for (var i = 0; i < response.length; i++) {
                            var data = response[i]
                            var dateStart = moment(data.dateStart)
                            var dateEnd = moment(data.dateEnd)
                            data.dateStart = dateStart.subtract((parseFloat(data.utcOffset) + dateStart.utcOffset()),'minute')
                            data.dateEnd = dateEnd.subtract(parseFloat(data.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                            var dstChecking = moment(data.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                            if(dstChecking.isAfter(data.dateStart)){
                                data.dateStart = data.dateStart.subtract(dstVariable,"minute")
                                data.dateEnd = data.dateEnd.subtract(dstVariable,"minute")
                            }
                            await profileStore.mapAdditionalProfileDataAsync(data.act);
                            mapped.push(data)
                        }
                        return mapped;
                    } else {
                        return [];
                    }
                })
            );
        } else {
            return agent.Booking.loadEntertainmentUpcomingsGigsWithoutAdhoc(profileId, startDateParam, endDateParam).then(
                action(async response => {
                    if (response && response.length > 0 && response !== undefined) {
                        var mapped = [];
                        for (var i = 0; i < response.length; i++) {
                            var data = response[i]
                            var dateStart = moment(data.dateStart)
                            var dateEnd = moment(data.dateEnd)
                            data.dateStart = dateStart.subtract((parseFloat(data.utcOffset) + dateStart.utcOffset()),'minute')
                            data.dateEnd = dateEnd.subtract(parseFloat(data.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                            var dstChecking = moment(data.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                            if(dstChecking.isAfter(data.dateStart)){
                                data.dateStart = data.dateStart.subtract(dstVariable,"minute")
                                data.dateEnd = data.dateEnd.subtract(dstVariable,"minute")
                            }
                            await profileStore.mapAdditionalProfileDataAsync(data.act);
                            mapped.push(data)
                        }
                        return mapped;
                    } else {
                        return [];
                    }
                })
            );
        }
    }

    @action
    loadEntertainmentUpcomingGigsWithoutAdhocGigGuideEmbed(profileId) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        reportStore.setFilterType("month")

        const { startDate, endDate } = reportStore;

        let startDateParam = startDate ? moment(startDate).format("MM-DD-YYYY") : undefined;
        let endDateParam = endDate ? moment(endDate).add(4, 'M').format("MM-DD-YYYY") : undefined;

        return agent.Booking.loadEntertainmentUpcomingsGigsWithoutAdhocGigGuideEmbed(profileId, startDateParam, endDateParam).then(
            action(async response => {
                if (response !== 'undefined' && response !== undefined) {
                    this.embedUpcomingGigs = response ? response : []

                    for (var i = 0; i < response.length; i++) {
                        var gig = response[i];
                        var dateStart = moment(gig.dateStart)
                        var dateEnd = moment(gig.dateEnd)
                        gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                        gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                    
                        var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                        if(dstChecking.isAfter(gig.dateStart)){
                            gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                            gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                        }
                        var newBackgroundImageAct = {}
                        var newBackgroundImageVenue = {}
                        var newProfileActImageAct = {}
                        if (response[i].venue && response[i].venue.backgroundImages.length > 0) {
                            newBackgroundImageVenue.url960 = await CacheHelper.getImgLink(response[i].venue.backgroundImages[0].url960);
                            newBackgroundImageVenue.url = await CacheHelper.getImgLink(response[i].venue.backgroundImages[0].urlOriginal);
                            newBackgroundImageVenue.url1920 = await CacheHelper.getImgLink(response[i].venue.backgroundImages[0].url1920);
                        }
                        if (response[i].act && response[i].act.backgroundImages.length > 0) {
                            newBackgroundImageAct.url960 = await CacheHelper.getImgLink(response[i].act.backgroundImages[0].url960);
                            newBackgroundImageAct.url = await CacheHelper.getImgLink(response[i].act.backgroundImages[0].urlOriginal);
                            newBackgroundImageAct.url1920 = await CacheHelper.getImgLink(response[i].act.backgroundImages[0].url1920);
                        }
                        if (response[i].act && response[i].act.profileImage) {
                            newProfileActImageAct.url400 = await CacheHelper.getImgLink(response[i].act.profileImage.url400);
                            newProfileActImageAct.url = await CacheHelper.getImgLink(response[i].act.profileImage.urlOriginal);
                            newProfileActImageAct.url200 = await CacheHelper.getImgLink(response[i].act.profileImage.url200);
                        }
                        runInAction(() => {
                            this.embedUpcomingGigs[i] = response[i]
                            if (response[i].venue && response[i].venue.backgroundImages && response[i].venue.backgroundImages.length > 0) {
                                this.embedUpcomingGigs[i].venue.backgroundImagesNew = newBackgroundImageVenue
                            }
                            if (response[i].act && response[i].act.backgroundImages && response[i].act.backgroundImages.length > 0) {
                                this.embedUpcomingGigs[i].act.backgroundImagesNew = newBackgroundImageAct
                            }
                            if (response[i].act && response[i].act.profileImage) {
                                this.embedUpcomingGigs[i].act.newProfileActImageAct = newProfileActImageAct
                            }
                        })

                    }

                }

                return this.embedUpcomingGigs;
            })
        );
    }
    @action
    resetAllGigs(){
        this.showMoreAll = false
        this.allGigs = []
        this.allPage = 1
    }

    @action
    resetCompletedGigs(){
        this.showMoreCompleted = false
        this.completed = []
        this.completedPage = 1
    }

    @action
    resetUpcomingGigs(){
        this.showMoreUpcoming = false
        this.upcomings = []
        this.upcomingPage = 1
    }

    @action
    resetSocials(){
        this.showMoreSocial = false
        this.socials = []
    }

    @action
    resetRequestGigs(){
        this.showMoreRequest = false
        this.requests = []
        this.draftPage = 1
    }

    @action
    setDraftPage(page){
        this.draftPage = page
    }
    @action
    setUpcomingPage(page){
        this.upcomingPage = page
    }
    @action
    setCompletedPage(page){
        this.completedPage = page
    }
    @action
    setCancelledPage(page){
        this.cancelledPage = page
    }
    @action
    setAllPage(page){
        this.allPage = page
    }

    @action
    resetCancelledGigs(){
        this.showMoreCancelled = false
        this.cancelled = []
        this.cancelledPage = 1
    }
    
    @action
    loadAllGigs(profileId, page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId, actId, creatorId} = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        return agent.Booking.loadAllGigs(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId, actId, page, creatorId).then(
            action(response => {
                for (let gig of response.bookings) {
                    var dateStart = moment(gig.dateStart)
                    var dateEnd = moment(gig.dateEnd)
                    gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                    gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(gig.dateStart)){
                        gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                        gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                this.allGigs = this.allGigs.concat(response.bookings);
                this.showMoreAll = response.isMore;
                this.bookingCreators = _.uniqBy(this.bookingCreators.concat(response.bookings.map(x=>x.createdBy)), "id")

                return response;
            })
        );
    }
    @action
    getCompletedGigs(profileId,page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId, actId, creatorId } = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        return agent.Booking.loadCompletedGigs(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId, actId, page, creatorId).then(
            action(response => {
                for (let gig of response.bookings) {
                    var dateStart = moment(gig.dateStart)
                    var dateEnd = moment(gig.dateEnd)
                    gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                    gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(gig.dateStart)){
                        gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                        gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                this.completed = this.completed.concat(response.bookings);
                this.showMoreCompleted = response.isMore;
               
                this.bookingCreators = _.uniqBy(this.bookingCreators.concat(response.bookings.map(x=>x.createdBy)), "id")

                return response;
            })
        );
    }


    @action
    resetBookingCreators() {
        this.bookingCreators = []
    }
    @action
    showLoadingState() {
        this.isLoading = true;
    }

    @action
    hideLoadingState() {
        this.isLoading = false;
    }

    @action
    getCancelledGigs(profileId,page) {
        const currentProfile = profileStore.getCurrentProfile();
        if (!profileId) {
            profileId = currentProfile?.id;
        }

        const { startDate, endDate, organisationId, stateId, regionId, venueId, actId, creatorId} = reportStore;

        let startDateParam = startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;
        let endDateParam = endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss.SSSS") : undefined;

        return agent.Booking.loadCancelledGigs(profileId, startDateParam, endDateParam, organisationId, stateId, regionId, venueId, actId,page, creatorId).then(
            action(response => {
                if (response) {
                    for (let gig of response.bookings) {
                        var dateStart = moment(gig.dateStart)
                        var dateEnd = moment(gig.dateEnd)
                        gig.dateStart = dateStart.subtract((parseFloat(gig.utcOffset) + dateStart.utcOffset()),'minute')
                        gig.dateEnd = dateEnd.subtract(parseFloat(gig.utcOffset) + dateEnd.utcOffset(),'minute'); 
                    
                        var dstChecking = moment(gig.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                        if(dstChecking.isAfter(gig.dateStart)){
                            gig.dateStart = gig.dateStart.subtract(dstVariable,"minute")
                            gig.dateEnd = gig.dateEnd.subtract(dstVariable,"minute")
                        }
                    }
                    this.cancelled = this.cancelled.concat(response.bookings);
                    this.showMoreCancelled = response.isMore;
                    this.bookingCreators = _.uniqBy(this.bookingCreators.concat(response.bookings.map(x=>x.createdBy)), "id")

                }
                return response;
            })
        );
    }

    @action
    saveStatus(id, status, showLoading = true) {
        const currentProfile = profileStore.getCurrentProfile();
        if (this.booking) {
            if (showLoading) {
                this.isLoading = true;
            }
            if(this.cancellationReason){
                this.booking.cancellationReason = this.cancellationReason;
                this.booking.cancelledById= currentProfile?.id,
                this.booking.cancelledAt= new Date()
            }
            this.booking.status = status; //  Accept = 3,     // accepted by act admin
            return agent.Booking.updateStatus(id, this.booking).finally(
                action(() => {
                    this.isLoading = false;
                })
            );
        }
        return Promise.resolve();
    }

    @action
    saveLineupStatus(status) {
        var lineup = { id: this.booking.myLineup.id, status: status, bookingId: this.booking.id };
        this.isLoading = true
        return agent.Lineup.updateStatus(lineup)
            .then(
                action(result => {
                    // set status
                    if (this.booking.myLineup && this.booking.myLineup.status !== status) {
                        this.booking.myLineup.status = status;
                    }
                    this.isLoading = false
                })
            )
            .catch(err => {
                console.log(err, "error")
                this.isLoading = false
            })
    }

    @action
    initializeBooking(actId, venueId, bookingType) {
        const currentProfile = profileStore.getCurrentProfile();
        this.setIsActLoaded(false);
        return agent.Booking.initializeBook(actId, venueId).then(
            action(res => {
                this.booking.title = undefined;

                if (res) {
                    let tempTitle = (res.actName ? res.actName : "") + (res.venue ? "@" : "") + (res.venue ? res.venue.profileName : "");
                    this.booking.id = undefined;
                    this.booking.tempTitle = tempTitle;
                    this.booking.actId = res.actId;
                    this.booking.venueId = res.venueId;
                    this.booking.actName = res.actName || res.act ? res.act.actName : "";
                    this.booking.venue = res.venue;
                    this.booking.act = res.act;

                    this.booking.actType = res.act ? res.act.actTypeId : undefined;
                    this.booking.actLineup = res.act ? res.act.lineUpCount : undefined;

                    this.booking.venueContact =
                        res.venueContact && res.venueContact.length > 0 ? res.venueContact : res.venue ? res.venue.contactName : "";
                    if (res.venue && res.venue.members) {
                        var venueContactPerson = res.venue.members.filter(x => x.booking || x.manage);
                        if (venueContactPerson && venueContactPerson[0]) {
                            this.booking.venueContact = venueContactPerson[0].name;
                        }
                    }
                    
                    this.booking.venueAddress =
                        res.venueAddress && res.venueAddress.length > 0 ? res.venueAddress : res.venue ? `${res.venue.street ? res.venue.street +", " : ""}${res.venue.city ? res.venue.city+", ": ""}${res.venue.state ? res.venue.state: ""}` : "";
                    this.booking.venuePhone = res.venuePhone && res.venuePhone.length > 0 ? res.venuePhone : res.venue ? res.venue.contactPhone : "";
                    this.booking.venueContract =
                        res.venueContract && res.venueContract.length > 0 ? res.venueContract : res.venue ? res.venue.termCondition : "";
                    this.booking.venueName =
                        res.venueName && res.venueName.length > 0 ? res.venueName : res.venue ? res.venue.profileName : "";
                    this.booking.agentContract = res.agentContract || res.termCondition;
                    if (res.clientContract) {
                        this.booking.clientContract = res.clientContract;
                    }
                    // if( res.agentContract || res.termCondition){
                    //     this.booking.agentContract = res.agentContract || res.termCondition;
                    // }


                }
                this.booking.provider = currentProfile?.type !== ProfileType.Agent.ordinal ? "" : currentProfile?.type == ProfileType.Agent.ordinal && !currentProfile?.isConsultant && currentProfile?.profileName ? currentProfile?.profileName : res.agentProfileName ? res.agentProfileName : "";
                this.booking.agentId = currentProfile?.isConsultant ? currentProfile?.myAgency?.agent?.id : currentProfile?.id;
                this.booking.ticketUrl = "";
                this.booking.sharePermission = true;
                this.booking.shareUpdate = bookingType && bookingType === BookingType.Adhoc.ordinal ? false : true;
                this.booking.displayGig = bookingType && bookingType === BookingType.Adhoc.ordinal ? false : true;
                this.booking.immediateInvoice = false;

                this.booking.createdBy = currentProfile;
                this.booking.notes = res.venue ? res.venue.notes || "" : "";
                this.booking.requirements = res.venue ? res.venue.requirements || "" : "";

                if (res.bookingVibes) {
                    this.vibes = res.bookingVibes.map(vibe => vibe.vibeId).join();
                }
                if (res.bookingGenres) {
                    this.genres = res.bookingGenres.map(genre => genre.genreId).join();
                }
                if (currentProfile?.type === ProfileType.Staff.ordinal) {
                    this.booking.agentId = undefined;
                    this.booking.actFee = 0;
                    this.booking.agentCommissionAmount = 0;
                    this.booking.agentCommissionFee = 0;
                }

                this.booking.notifyAct = false;
                this.booking.notifyClient = false;
                this.booking.notifyAgent = false;
                this.booking.saveNotesAsTemplate = false;
                this.booking.saveRequirementsAsTemplate = false;
                this.booking.signed = false;
                this.booking.signedById = undefined;
                this.booking.signedBy = undefined;
                this.booking.signedAt = undefined;
                this.error = undefined;
                this.isReadonly = false;
                this.setTempBookingTitle();
                this.setPrivateBookingHeadingFromCalendar();

                const reminder = 30 - (moment().minute() % 30);
                this.booking.timeStart = moment().add(reminder, 'minutes')
                this.booking.timeEnd = moment(this.booking.timeStart).add(3, "h");

                return this.getBookableActs().then(
                    action(() => {
                        // if (typeof this.actId !== "undefined") {
                        //     this.bookableActs = this.bookableActs.filter(x => x.id == this.actId);
                        //     if (this.booking.act) {
                        //         this.booking.actLineup = this.booking.act.lineUpCount;
                        //         this.booking.actType = this.booking.act.actTypeId;
                        //     }
                        // }
                        this.setIsActLoaded(true)
                        return res;
                    })
                );
            })
        );
    }

    @action
    initializeEditBooking(id, bookingRequestId) {
        // templateStore.showLoadingScreen();
        this.isLoading = true;
        const currentProfile = profileStore.getCurrentProfile();
        this.setIsActLoaded(false)
        return agent.Booking.getDetail(id, bookingRequestId, currentProfile?.id)
            .then(
                action(res => {
                    profileStore.mapAdditionalProfileData(res.createdBy);
                    this.booking = res;
                    let tempTitle = "";
                    if (this.booking.bookingRequest) {
                        tempTitle =
                            this.booking.bookingRequest.act.actName +
                            "@" +
                            (this.booking.venue ? this.booking.venue.profileName : this.booking.venueName);
                    } else {
                        tempTitle = this.getBookingTitle(this.booking); // this.booking.act.actName + "@" + (this.booking.venue ? this.booking.venue.profileName : this.booking.venueName);
                    }
                    this.booking.tempTitle = tempTitle;
                    this.booking.actAdminId = res?.act?.actAdminId

                    this.booking.title = this.booking.title && this.booking.title !== "" ? this.booking.title : undefined;

                    this.booking.actType = res.act ? res.act.actTypeId : res.actTypeId;
                    if (res.bookingVibes) {
                        this.vibes = res.bookingVibes.map(vibe => vibe.vibeId).join();
                    }
                    if (res.bookingGenres) {
                        this.genres = res.bookingGenres.map(genre => genre.genreId).join();
                    }
                    var dateStart = moment(this.booking.dateStart)
                    var dateEnd = moment(this.booking.dateEnd)
                    this.booking.dateStart = dateStart.subtract((parseFloat(this.booking.utcOffset) + dateStart.utcOffset()),'minute')
                    this.booking.dateEnd = dateEnd.subtract(parseFloat(this.booking.utcOffset) + dateEnd.utcOffset(),'minute'); 
                    var dstChecking = moment(this.booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(this.booking.dateStart)){
                        this.booking.dateStart = this.booking.dateStart.subtract(dstVariable,"minute")
                        this.booking.dateEnd = this.booking.dateEnd.subtract(dstVariable,"minute")
                    }
                    this.booking.actLineup = res.act ? res.act.lineUpCount : res.lineUpCount;
                    this.booking.clientContract = res.clientContract;
                    this.booking.venueContract = res.venueContract;
                    this.booking.agentContract = res.agentContract;
                    this.booking.actName = res.actName || res.act ? res.act.actName : "";
                    this.booking.repeatCount = this.booking.repeatCount ? this.booking.repeatCount : 1;
                    if (this.booking.type === BookingType.Normal.ordinal && this.booking.status === BookingStatusType.New.ordinal) {
                        this.validateBooking();
                    }
                    if (this.booking.type === BookingType.Adhoc.ordinal && this.booking.status === BookingStatusType.New.ordinal) {
                        this.validatePrivateBooking();
                    }
                    this.setTempBookingTitle();
                    this.setPrivateBookingHeading();
                    if (res.signed && res.signedById) {
                        // pull signer name
                        this.booking.signedBy = {
                            firstName: res.signedBy.firstName,
                            lastName: res.signedBy.lastName
                        }

                    }
                    templateStore.hideLoadingScreen();

                    return this.getBookableActs().then(action(() => {
                        if (this.actId) {

                            this.bookableActs = this.bookableActs.filter(x => parseInt(x.id) === parseInt(this.actId));
                            if (this.booking.act) {
                                this.booking.actLineup = this.booking.act.lineUpCount;
                                this.booking.actType = this.booking.act.actTypeId;
                            }
                        }
                        this.booking.tempClientContract = res.clientContract;

                        // templateStore.hideLoadingScreen();
                        this.setIsActLoaded(true)
                        return res;
                    }));
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    resetMyBooking() {
        this.myBooking = [];
    }

    @action
    resetMyActBooking() {
        this.myActBooking = [];

    }

    @action
    resetMyCalendarActBooking() {
        this.myCalendarActBooking = [];
    }

    @action
    getBookingById(id) {
        return agent.BookingStation.get(id);
    }

    @action
    createBooking(book) {
        return agent.BookingStation.save();
    }

    @action
    loadMyBookingPagination(userId, type, startDate, endDate) {
        const currentProfile = profileStore.getCurrentProfile();
        var self = this;
        this.isLoading = true;
        this.isLoadingVenue = true;
        // templateStore.showLoadingScreen();
        let id = userId || currentProfile?.id;
        var utcOffset = -(moment(startDate).utcOffset())
        return agent.Booking.getBookingFilterDate(id, startDate, endDate, utcOffset)
            .then(
                action(res => {
                    
                    switch (type) {
                        case "agent":
                            for (let booking of res.agentBookingRequests) {
                                var dateStart = moment(booking.dateStart)
                                var dateEnd = moment(booking.dateEnd)
                                booking.dateStart= dateStart.subtract(parseFloat(booking.utcOffset) + (dateStart.utcOffset()),'minute');
                                booking.dateEnd= dateEnd.subtract(parseFloat(booking.utcOffset) + (dateEnd.utcOffset()),'minute');
                                var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                if(dstChecking.isAfter(booking.dateStart)){
                                    booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                                    booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                                }
                               
                            }
                            self.myBooking = res.agentBookingRequests;
                            break;
                        case "staff":
                        case "venue":
                            for (let booking of res.venueBookingRequests) {
                                var dateStart = moment(booking.dateStart)
                                var dateEnd = moment(booking.dateEnd)
                                booking.dateStart= dateStart.subtract(parseFloat(booking.utcOffset) + (dateStart.utcOffset()),'minute');
                                booking.dateEnd= dateEnd.subtract(parseFloat(booking.utcOffset) + (dateEnd.utcOffset()),'minute');
                                var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                if(dstChecking.isAfter(booking.dateStart)){
                                    booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                                    booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                                }
                            }
                            self.myBooking = res.venueBookingRequests;
                            break;
                        default:
                            for (let booking of res.actBookingRequests) {
                                var dateStart = moment(booking.dateStart)
                                var dateEnd = moment(booking.dateEnd)
                                booking.dateStart= dateStart.subtract(parseFloat(booking.utcOffset) + (dateStart.utcOffset()),'minute');
                                booking.dateEnd= dateEnd.subtract(parseFloat(booking.utcOffset) + (dateEnd.utcOffset()),'minute');
                                var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                console.log(booking.dateStart)
                                if(dstChecking.isAfter(booking.dateStart)){
                                    booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                                    booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                                }
                            }
                            self.myActBooking = res.actBookingRequests;
                            break;
                    }
                })
            )
            .finally(
                action(() => {
                    // templateStore.hideLoadingScreen();
                    this.isLoading = false;
                    this.isLoadingVenue = false;
                })
            );
    }

    mapEvent(event) {
        event.title = "Unavailable";
        event.start = new Date(event.dateStart.toISOString());
        event.end = new Date(event.dateEnd.toISOString());
        event.type = "google-calendar";
        event.id = parseInt(event.id);
        event.venueName = "";
        let endHour = event.end.getHours();
        let endMinute = event.end.getMinutes();

        // add 1 minute to end time for react-big-calendar display purpose only in month view
        // real event.end time will be loaded from backend upon edit/click event
        if (endHour === 0 && endMinute === 0) {
            event.end.setMinutes(1);
        }
    }

    @action
    loadMyBookingRelatedAct(actId,startDate, endDate){

        return agent.Booking.getBookingRelatedAct(actId,startDate, endDate)
            .then(
                action(res => {
                    
                    res.forEach(event => {
                        var dateStart = moment(event.dateStart)
                        var dateEnd = moment(event.dateEnd)
                        event.dateStart= dateStart.subtract(parseFloat(event.utcOffset) + (dateStart.utcOffset()),'minute');
                        event.dateEnd= dateEnd.subtract(parseFloat(event.utcOffset) + (dateEnd.utcOffset()),'minute');
                       
                      
                        var dstChecking = moment(event.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                        if(dstChecking.isAfter(event.dateStart)){
                            event.dateStart = event.dateStart.subtract(dstVariable,"minute")
                            event.dateEnd = event.dateEnd.subtract(dstVariable,"minute")
                        }
                        
                        this.mapEvent(event);
                    });
                    this.relatedBookings = res
                })
            )
            .finally(
                action(() => {
                    // templateStore.hideLoadingScreen();
                    // this.isLoading = false;
                    // this.isLoadingVenue = false;
                })
            );
        
    }

    @action
    getAllowBooking(profile) {

        return agent.Booking.getAllowBooking(profile.organisation[0].id, profile.hasOrganisation)
            .then(
                action(res => {
                    this.bookingCreators = res
                })
            )
            .finally(
                action(() => {
                })
            );
    }
    @action
    loadMyBooking(userId, type) {
        const currentProfile = profileStore.getCurrentProfile();
        var self = this;
        this.isLoading = true;
        this.isLoadingVenue = true;
        // templateStore.showLoadingScreen();
        let id = userId || currentProfile?.id;
        return agent.Booking.getBooking(id)
            .then(
                action(res => {

                    switch (type) {
                        case "agent":
                            for (let booking of res.agentBookingRequests) {
                                var dateStart = moment(booking.dateStart)
                                var dateEnd = moment(booking.dateEnd)
                                booking.dateStart= dateStart.subtract(parseFloat(booking.utcOffset) + (dateStart.utcOffset()),'minute');
                                booking.dateEnd= dateEnd.subtract(parseFloat(booking.utcOffset) + (dateEnd.utcOffset()),'minute');
                       
                                var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                if(dstChecking.isAfter(booking.dateStart)){
                                    booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                                    booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                                }
                            }
                            self.myBooking = res.agentBookingRequests;
                            break;
                        case "staff":
                        case "venue":
                            for (let booking of res.venueBookingRequests) {
                                var dateStart = moment(booking.dateStart)
                                var dateEnd = moment(booking.dateEnd)
                                booking.dateStart= dateStart.subtract(parseFloat(booking.utcOffset) + (dateStart.utcOffset()),'minute');
                                booking.dateEnd= dateEnd.subtract(parseFloat(booking.utcOffset) + (dateEnd.utcOffset()),'minute');
                       
                                var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                if(dstChecking.isAfter(booking.dateStart)){
                                    booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                                    booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                                }
                            }
                            self.myBooking = res.venueBookingRequests;
                            break;
                        default:
                            for (let booking of res.actBookingRequests) {
                                var dateStart = moment(booking.dateStart)
                                var dateEnd = moment(booking.dateEnd)
                                booking.dateStart= dateStart.subtract(parseFloat(booking.utcOffset) + (dateStart.utcOffset()),'minute');
                                booking.dateEnd= dateEnd.subtract(parseFloat(booking.utcOffset) + (dateEnd.utcOffset()),'minute');
                       
                                var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                                if(dstChecking.isAfter(booking.dateStart)){
                                    booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                                    booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                                }
                            }
                            self.myActBooking = res.actBookingRequests;
                            break;
                    }
                })
            )
            .finally(
                action(() => {
                    // templateStore.hideLoadingScreen();
                    this.isLoading = false;
                    this.isLoadingVenue = false;
                })
            );
    }

    @action
    loadMyBookingByProfileIds(userIds, type) {
        var self = this;
        // templateStore.showLoadingScreen();
        this.isLoading = true;
        this.isLoadingAct = true;
        return agent.Booking.getBookingByProfileIds(userIds)
            .then(
                action(res => {
                    switch (type) {
                        case "agent":
                            self.myBooking = res.agentBookingRequests;
                            break;
                        case "venue":
                            self.myBooking = res.venueBookingRequests;
                            break;
                        default:
                            self.myActBooking = res.actBookingRequests;
                            break;
                    }
                })
            )
            .finally(
                action(() => {
                    // templateStore.hideLoadingScreen();
                    this.isLoading = false;
                    this.isLoadingAct = false;
                })
            );
    }

    @action
    getBookingSummary(bookingId) {
        var self = this;
        templateStore.showLoadingScreen();
        return agent.Booking.getSummary(bookingId).then(
            action(res => {
                res.originalDateStart = res.dateStart
                var dateStart = moment(res.dateStart)
                var dateEnd = moment(res.dateEnd)
                var dateStartNeutral= dateStart.subtract(parseFloat(res.utcOffset) + (dateStart.utcOffset()),'minute');
                var dateEndNeutral= dateEnd.subtract(parseFloat(res.utcOffset) + (dateEnd.utcOffset()),'minute');
       
                var dstChecking = moment(dateStartNeutral).set({hour:2,minute:0,second:0,millisecond:0})
                if(dstChecking.isAfter(dateStartNeutral)){
                    dateStartNeutral = dateStartNeutral.subtract(dstVariable,"minute")
                    dateEndNeutral = dateEndNeutral.subtract(dstVariable,"minute")
                    res.originalDateStart = moment(res.originalDateStart).subtract(dstVariable,"minute")
                }
                res.dateStart= dateStartNeutral.format("DD-MM-YYYY");
                res.dateEnd= dateEndNeutral.format("DD-MM-YYYY");
               
                this.bookingDetail = res;

                let tempTitle = `${res.act ? res.act.actName : ""}@${res.venue ? res.venue.profileName : res.venueName}`;

                this.bookingDetail.tempTitle = tempTitle;
                templateStore.hideLoadingScreen();
            })
        );
    }

    @action
    getBookingDetail(id, bookingRequestId) {
        var self = this;
        templateStore.showLoadingScreen();

        const currentProfile = profileStore.getCurrentProfile();
        return agent.Booking.getDetail(id, bookingRequestId, currentProfile?.id).then(
            action(res => {
                var dateStart = moment(res.dateStart)
                var dateEnd = moment(res.dateEnd)
                res.dateStart = dateStart.subtract((parseFloat(res.utcOffset) + dateStart.utcOffset()),'minute')
                res.dateEnd = dateEnd.subtract(parseFloat(res.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                var dstChecking = moment(res.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                if(dstChecking.isAfter(res.dateStart)){
                    res.dateStart = res.dateStart.subtract(dstVariable,"minute")
                    res.dateEnd = res.dateEnd.subtract(dstVariable,"minute")
                }
                self.bookingDetail = res;
                let tempTitle = this.getBookingTitle(res); //  res.act.actName + "@" + (res.venue ? res.venue.profileName : res.venueName);

                self.bookingDetail.tempTitle = tempTitle;
                this.setTempBookingTitle();
                templateStore.hideLoadingScreen();
            })
        );
    }

    @action getDetailBookingCalendar(id, bookingRequestId) {
        var self = this;
        templateStore.showLoadingScreen();

        const currentProfile = profileStore.getCurrentProfile();
        return agent.Booking.getDetailCalendar(id, bookingRequestId, currentProfile?.id).then(
            action(res => {
                var dateStart = moment(res.dateStart)
                var dateEnd = moment(res.dateEnd)
                res.dateStart = dateStart.subtract((parseFloat(res.utcOffset) + dateStart.utcOffset()),'minute')
                res.dateEnd = dateEnd.subtract(parseFloat(res.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                var dstChecking = moment(res.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                if(dstChecking.isAfter(res.dateStart)){
                    res.dateStart = res.dateStart.subtract(dstVariable,"minute")
                    res.dateEnd = res.dateEnd.subtract(dstVariable,"minute")
                }
                res.dateStart = res.dateStart.format("DD-MM-YYYY");
                res.dateEnd = res.dateEnd.format("DD-MM-YYYY");
                self.bookingDetail = res;
                let tempTitle = this.getBookingTitle(res); //  res.act.actName + "@" + (res.venue ? res.venue.profileName : res.venueName);

                self.bookingDetail.tempTitle = tempTitle;
                this.setTempBookingTitle();
                templateStore.hideLoadingScreen();
            })
        );
    }

    @action
    savePrivateBooking(finalize, isOwner) {
        const currentProfile = profileStore.getCurrentProfile();
        this.booking.type = BookingType.Adhoc.ordinal;
        this.booking.finalize = finalize ? finalize : false;
        if (isOwner && currentProfile?.type === ProfileType.Agent.ordinal) {
            this.booking.actFee = this.booking.gigFee - (this.booking.agentCommissionAmount ? this.booking.agentCommissionAmount : 0)
        }
        return this.saveBooking();
    }


    @action
    saveBooking() {
        let data = this.prepareData();
        if (this.error !== undefined) {
            return data;
        }

        // templateStore.showLoadingScreen();
        this.isSaving = true;

        // console.log("check booking availability");

        return this.checkBookingAvailability()
            .then(
                response => {
                    return agent.Booking.save(data).then(
                        action(async response => {
                            //this.resetBooking();
                            // const updatedData = await this.load(response.id);
                            profileStore.mapAdditionalProfileData(response.createdBy);
                            // response.act = updatedData?.act;
                            this.booking = response;
                            templateStore.hideLoadingScreen();
                            return response;
                        })
                    );
                },
                err => {
                    templateStore.hideLoadingScreen();
                    return Promise.reject(err);
                }
            )
            .finally(
                action(() => {
                    templateStore.hideLoadingScreen();
                    this.isSaving = false;
                })
            );
    }

    
    @action
    saveBookingWithoutCheckingAvailability() {
        let data = this.prepareData();

        templateStore.showLoadingScreen();

        return agent.Booking.save(data)
            .then(
                action(response => {
                    //this.resetBooking();
                    profileStore.mapAdditionalProfileData(response.createdBy);
                    this.booking = response;
                    templateStore.hideLoadingScreen();
                    return response;
                })
            )
            .finally(
                action(() => {
                    templateStore.hideLoadingScreen();
                })
            );
    }

    prepareData = () => {
        const currentProfile = profileStore.getCurrentProfile();
        if (currentProfile?.type === ProfileType.Agent.ordinal) {
            this.booking.agentId = currentProfile?.id;
            // data.agentId = currentProfile?.isConsultant ? currentProfile?.myAgencies.agentId : currentProfile?.id;
        }
        if (!this.booking.type) {
            this.booking.type = BookingType.Normal.ordinal;
        }
        if (!this.validateBooking() && this.booking.type === BookingType.Normal.ordinal) {
            return Promise.reject("Invalid");
        } else if (!this.validatePrivateBooking() && this.booking.type === BookingType.Adhoc.ordinal) {
            return Promise.reject("Invalid");
        }

     
        let vibesArray = this.vibes === "" || this.vibes.length === 0 ? [] : this.vibes.split(",").map(vibeId => ({ id: vibeId }));
        let genresArray = this.genres === "" || this.genres.length === 0 ? [] : this.genres.split(",").map(genreId => ({ id: genreId }));
        

        let data = {
            id: this.booking.id,
            actFee: this.booking.actFee,
            actId: this.booking.actId,
            agentCommissionFee: this.booking.agentCommissionFee | 0,
            agentCommissionAmount: this.booking.agentCommissionAmount | 0,
            agentCommissionType: this.booking.agentCommissionType,
            agentId: this.booking.agentId,
            title: this.getBookingTitle(this.booking),
            dress: this.booking.dress,
            gigFee: this.booking.gigFee,
            notes: this.booking.notes,
            provider: this.booking.provider,
            requirements: this.booking.requirements,
            venueContract: this.booking.venueContract,
            agentContract: this.booking.agentContract,
            clientContract: this.booking.clientContract,
            setNumber: this.booking.setNumber,
            venueId: this.booking.venueId,
            venueContact: this.booking.venueContact,
            venueAddress: this.booking.venueAddress,
            lineUpCount: this.booking.actLineup,
            actTypeId: this.booking.actType,
            venuePhone: this.booking.venuePhone,
            privateHireId: this.booking.privateHireId,
            vibes: vibesArray,
            genres: genresArray,
            timeStart: moment(this.booking.timeStart, "HH:mm")
                .local()
                .format("HH:mm"),
            timeEnd: moment(this.booking.timeEnd, "HH:mm")
                .local()
                .format("HH:mm"),
            loadInStart: this.booking.loadInStart
                ? moment(this.booking.loadInStart, "HH:mm")
                    .local()
                    .format("HH:mm")
                : undefined,
            loadInEnd: this.booking.loadInEnd
                ? moment(this.booking.loadInEnd, "HH:mm")
                    .local()
                    .format("HH:mm")
                : undefined,
            soundCheckStart: this.booking.soundCheckStart
                ? moment(this.booking.soundCheckStart, "HH:mm")
                    .local()
                    .format("HH:mm")
                : undefined,
            soundCheckEnd: this.booking.soundCheckEnd
                ? moment(this.booking.soundCheckEnd, "HH:mm")
                    .local()
                    .format("HH:mm")
                : undefined,
            bookingDate: this.booking.bookingDate,
            dateStart: moment(this.booking.dateStart).endOf("day"),
            dateEnd: moment(this.booking.dateStart).endOf("day"),
            createdById: currentProfile?.id,
            repeatCount: typeof this.booking.repeatCount !== "undefined" ? this.booking.repeatCount : 1,
            repeatType: this.booking.repeatType,
            ticketUrl: this.booking.ticketUrl,
            sharePermission: this.booking.sharePermission,
            shareUpdate: this.booking.shareUpdate,
            displayGig: this.booking.displayGig,
            immediateInvoice: this.booking.immediateInvoice,

            independentVenueId: this.booking.independentVenueId,
            venueName: this.booking.venueName,
            venueEmail: this.booking.venueEmail,

            addToContact: this.booking.addToContact,
            clientFirstName: this.booking.clientFirstName,
            clientLastName: this.booking.clientLastName,
            clientAddress: this.booking.clientAddress,
            clientEmail: this.booking.clientEmail,
            clientPhone: this.booking.clientPhone,
            clientCompanyName: this.booking.clientCompanyName,

            depositAmount: this.booking.depositAmount,
            depositDue: this.booking.depositDue,
            depositDueDate: moment(this.booking.depositDueDate).endOf("day"),

            balanceDue: this.booking.balanceDue,
            balanceDueDate: moment(this.booking.balanceDueDate).endOf("day"),

            createInvoice: this.booking.createInvoice,

            type: this.booking.type,
            finalize: this.booking.finalize,
            notifyAct: this.booking.notifyAct,
            notifyClient: this.booking.notifyClient,
            notifyAgent: this.booking.notifyAgent,
            saveNotesAsTemplate: this.booking.saveNotesAsTemplate,
            saveRequirementsAsTemplate: this.booking.saveRequirementsAsTemplate,
            saveAsDraft: this.booking.saveAsDraft,
            signed: this.booking.signed || false,
            signedById: this.booking.signedById,
            signedBy: this.booking.signedBy,
            signedAt: this.booking.signedAt,
            utcOffset: -moment(this.booking.dateStart).utcOffset(),
            purchaseOrderCode: this.booking.purchaseOrderCode
        };

        if (this.booking.type === BookingType.Normal.ordinal && !this.booking.id) {
            data.depositAmount = 0;
            data.depositDue = 0;
            data.depositDueDate = moment(this.booking.depositDueDate).endOf("day");

            data.balanceDue = 0;
            data.balanceDueDate = moment(this.booking.balanceDueDate).endOf("day");

        }
        return data;
    };

    getBookingStatus = booking => {
        // const { currentProfile } = profileStore;
        // const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        // const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        // const isStaffOrAgent = isStaff || isAgent;

        if (booking.status === BookingStatusType.New.ordinal && booking.saveAsDraft) {
            return "Draft";
        }

        return BOOKING_STATUS[booking.status];
    };

    getBookingStatusClass = booking => {
        // const { currentProfile } = profileStore;
        // const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        // const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        // const isStaffOrAgent = isStaff || isAgent;

        if (booking.status === BookingStatusType.New.ordinal && booking.saveAsDraft) {
            return "Draft";
        }
        return BOOKING_STATUS_CLASS[booking.status];
    };

    generateBookings = bookings => {
        return bookings
            ? bookings.map(booking => {
                var timeStart = moment(booking.timeStart, "HH:mm");
                var timeEnd = moment(booking.timeEnd, "HH:mm");

                var loadInStart = booking.loadInStart ? moment(booking.loadInStart, "HH:mm") : undefined;
                var loadInEnd = booking.loadInEnd ? moment(booking.loadInEnd, "HH:mm") : undefined;

                var soundCheckStart = booking.soundCheckStart ? moment(booking.soundCheckStart, "HH:mm") : undefined;
                var soundCheckEnd = booking.soundCheckEnd ? moment(booking.soundCheckEnd, "HH:mm") : undefined;

                var calendarStartTime = moment(booking.dateStart)
                    .set({
                        hour: timeStart.get("hour"),
                        minute: timeStart.get("minute")
                    })
                    .toDate();

                var calendarEndTime = moment(booking.dateStart)
                    // .add(timeStart > timeEnd ? 1 : 0, "days")
                    .set({
                        hour: timeEnd.get("hour"),
                        minute: timeEnd.get("minute")
                    })
                    .toDate();

                //let tempTitle = (booking.act ? booking.act.actName : "") + "@" + (booking.venue ? booking.venue.profileName : booking.venueName);

                return {
                    id: booking.id,
                    title: this.getBookingTitle(booking),
                    actId: booking.actId,
                    venueId: booking.venueId,
                    start: calendarStartTime,
                    end: calendarEndTime,
                    timeStart: booking.timeStart,
                    timeEnd: booking.timeEnd,
                    loadInStart: loadInStart,
                    loadInEnd: loadInEnd,
                    soundCheckStart: soundCheckStart,
                    soundCheckEnd: soundCheckEnd,
                    venueName: booking.venue ? booking.venue.profileName : "",
                    type: "booking",
                    status: this.getBookingStatus(booking),
                    statusClass: this.getBookingStatusClass(booking),
                    saveAsDraft: booking.saveAsDraft,
                    statusNumber: booking.status,
                    bookingRequestId: booking.bookingRequestId
                    /*booking.status >= BookingStatusType.Accepted.ordinal &&
                  booking.status <= BookingStatusType.Paid.ordinal
                      ? "green"
                      : booking.status === BookingStatusType.Hold.ordinal
                          ? "yellow"
                          : booking.status === BookingStatusType.Declined.ordinal ?
                              "red":
                                  booking.status === BookingStatusType.Cancelled.ordinal
                                  ? "black" :
                                  booking.status === BookingStatusType.Completed.ordinal
                                  ? "grey"
                                  : "blue"*/
                };
            })
            : [{}];
    };

    @action
    checkBookingAvailability() {
        const currentProfile = profileStore.getCurrentProfile();
        let data = {
            id: this.booking.id,
            actId: this.booking.actId,
            venueId: this.booking.venueId,
            timeStart: moment(this.booking.timeStart, "HH:mm")
                .local()
                .format("HH:mm"),
            timeEnd: moment(this.booking.timeEnd, "HH:mm")
                .local()
                .format("HH:mm"),
            dateStart: moment(this.booking.dateStart).endOf("day"),
            dateEnd: moment(this.booking.dateStart).endOf("day"),
            repeatCount: this.booking.repeatCount,
            repeatType: this.booking.repeatType,
            createdById: currentProfile?.id
        };
        return agent.Booking.checkAvailability(data).then(
            response => {
                return Promise.resolve();
            },
            err => {
                this.addErrors(["timeStart", "timeEnd"], "Invalid Timeslot");
                return Promise.reject(err.response.text);
            }
        );
    }

    @action
    cancelBooking() {
        const currentProfile = profileStore.getCurrentProfile();
        this.isLoading = true;

        if ((this.booking.createdById && this.booking.createdById === currentProfile?.id) || currentProfile?.type === ProfileType.Staff.ordinal) {
            var data = {
                id: this.booking.id,
                cancellationReason: this.cancellationReason,
                cancelledById: currentProfile?.id
            };
            return agent.Booking.cancelBooking(data).finally(
                action(() => {
                    this.isLoading = false;
                })
            );
        } else if (currentProfile?.type === ProfileType.Agent.ordinal && this.booking.isAdmin) {
            this.booking.status = BookingStatusType.CancelledByAct.ordinal;

            this.booking.cancelledById = currentProfile?.id;
            this.booking.cancellationReason = this.cancellationReason;

            return agent.Booking.updateStatus(currentProfile?.id, this.booking).finally(
                action(() => {
                    this.isLoading = false;
                })
            );
        } else if (currentProfile?.type === ProfileType.Musician.ordinal) {
            this.booking.status = BookingStatusType.CancelledByAct.ordinal;

            this.booking.cancelledById = currentProfile?.id;
            this.booking.cancellationReason = this.cancellationReason;

            return agent.Booking.updateStatus(currentProfile?.id, this.booking).finally(
                action(() => {
                    this.isLoading = false;
                })
            );
        } else if (currentProfile.type === ProfileType.PrivateHire.ordinal) {
            var data = {
                id: this.booking.id,
                cancellationReason: this.cancellationReason,
                cancelledById: currentProfile?.id
            };

            return agent.Booking.cancelBooking(data).finally(
                action(() => {
                    this.isLoading = false;
                })
            );
        }
    }

    @action
    resetBooking() {
        // this.privateBookingHeading = "Entertainer Booking request";
        this.setPrivateBookingHeading();
        this.tempBookingTitle = "";
        this.bookableActs = [];
        this.isPrivateHire = false;

        // by default start time would be the current hour. If its' current 4:27PM, the startTime would be 4PM.
        const startTime = moment().add(0 - moment().minute(), "m");
        if (moment())
            this.booking = {
                id: undefined,
                title: "",
                agentId: 0,
                actId: 0,
                venueId: undefined,
                provider: "",
                venue: "",
                actName: "",
                venueName: "",
                venueEmail: "",
                venueContact: "",
                venueAddress: "",
                venuePhone: "",
                setNumber: 1,
                dress: "",
                gigFee: undefined,
                repeatType: 0,
                repeatCount: 1,
                agentCommissionFee: undefined,
                agentCommissionAmount: 0,
                requirements: "",
                notes: "",
                venueContract: "",
                agentContract: "",
                actFee: undefined,
                commissionType: 0,
                agentCommissionType: 0,
                dateStart: new Date(),
                dateEnd: new Date(),
                timeStart: startTime,
                timeEnd: moment(startTime)
                    .add(3, "h")
                    .toDate(),
                loadInStart: undefined,
                loadInEnd: undefined,
                soundCheckStart: undefined,
                soundCheckEnd: undefined,
                ticketUrl: "",
                clientFirstName: "",
                clientLastName: "",
                clientAddress: "",
                clientEmail: "",
                clientPhone: "",
                clientContract: "",
                addToContact: false,
                depositAmount: undefined,
                depositDue: 0,
                depositDueDate: moment().endOf("day"),
                balanceDue: 0,
                balanceDueDate: moment().endOf("day"),
                createInvoice: true,
                independentVenueId: undefined,
                finalize: false,
                type: 0,
                clientCompanyName: "",
                actType: undefined,
                actLineup: undefined,
                saveAsDraft: false,
                notifyAct: false,
                notifyClient: false,
                notifyAgent: false,
                saveNotesAsTemplate: false,
                saveRequirementsAsTemplate: false,
                signed: false,
                signedById: undefined,
                signedBy: undefined,
                signedAt: false,
                forceAct: false,
                purchaseOrderCode: undefined,
                isAdmin: false,
                genres: "",
                vibes: ""
            };
    }

    @action
    sendChangeActRequest(acts, booking,title) {
        let requestData = [];
        const currentProfile = profileStore.getCurrentProfile();
        acts.forEach(act => {
            requestData.push({
                id: booking.id,
                actId: act.id,
                venueId: booking.venueId,
                dateStart: booking.dateStart,
                dateEnd: booking.dateEnd,
                timeStart: booking.timeStart,
                timeEnd: booking.timeEnd,
                createdById: currentProfile?.id,
                title: title ? title : booking.title
            });
        });
        if (requestData.length > 0) {
            return agent.BookingRequest.create(requestData).then(action(response => {
                this.booking.bookingRequests = response;

                return this.getBookableActs().then(action(() => {
                    if (this.actId) {
                        this.bookableActs = this.bookableActs.filter(x => parseInt(x.id) === parseInt(this.actId));
                        if (this.booking.act) {
                            this.booking.actLineup = this.booking.act.lineUpCount;
                            this.booking.actType = this.booking.act.actTypeId;
                        }
                    }
                    // templateStore.hideLoadingScreen();
                    return response;

                }));
            }));
        }

        return Promise.resolve();
    }

    @action
    duplicateBooking(bookingId,profileId){
        return agent.Booking.duplicateBooking(bookingId, profileId).then(response => {
            return response;
        });
    }

    @action
    deleteBooking(bookingId,profileId){
        return agent.Booking.deleteBooking(bookingId, profileId).then(response => {
            return response;
        });
    }
    @action
    acceptChangeActRequest(bookingRequestId) {
        return agent.BookingRequest.accept(bookingRequestId).then(response => {
            return response;
        });
    }

    @action
    acceptChangeActRequestWithProfileId(bookingRequestId,profileId) {
        return agent.BookingRequest.acceptBookingRequest(bookingRequestId,profileId).then(response => {
            return response;
        });
    }

    
    @action
    declineChangeActRequest(bookingRequestId) {
        return agent.BookingRequest.decline(bookingRequestId).then(response => {
            return response;
        });
    }

    @action
    venueStaffAcknowledgeGigs(bookingId) {
        const currentProfile = profileStore.getCurrentProfile();

        return agent.Booking.acknowledgeGigs(currentProfile?.id, bookingId).then(
            action(response => {
                this.upcomings = this.upcomings.map(x => {
                    if (x.id === response.id) {
                        x.acknowledge = response.acknowledge;
                        x.acknowledgeAt = response.acknowledgeAt;
                        x.acknowledgeById = response.acknowledgeById;
                        x.acknowledgeBy = response.acknowledgeBy;
                    }
                    return x;
                });
                return response;
            })
        );
    }

    @action
    venueStaffUnacknowledgeGigs(bookingId) {
        const currentProfile = profileStore.getCurrentProfile();

        return agent.Booking.unacknowledgeGigs(currentProfile?.id, bookingId).then(
            action(response => {
                this.upcomings = this.upcomings.map(x => {
                    if (x.id === response.id) {
                        x.acknowledge = response.acknowledge;
                        x.acknowledgeAt = response.acknowledgeAt;
                        x.acknowledgeById = response.acknowledgeById;
                    }
                    return x;
                });
                return response;
            })
        );
    }

    @action
    getMyCalendarBookings(profileId, startDate, endDate) {
        const currentProfile = profileStore.getCurrentProfile();

        if (!profileId) {
            profileId = currentProfile?.id;
        }

        return agent.Booking.getCalendarBooking(profileId, currentProfile?.id, startDate, endDate, -(moment(startDate).utcOffset())).then(
            action(response => {
                for (let booking of response) {
                    var dateStart = moment(booking.dateStart)
                    var dateEnd = moment(booking.dateEnd)
                    booking.dateStart = dateStart.subtract((parseFloat(booking.utcOffset) + dateStart.utcOffset()),'minute')
                    booking.dateEnd = dateEnd.subtract(parseFloat(booking.utcOffset) + dateEnd.utcOffset(),'minute'); 
                
                    var dstChecking = moment(booking.dateStart).set({hour:2,minute:0,second:0,millisecond:0})
                    if(dstChecking.isAfter(booking.dateStart)){
                        booking.dateStart = booking.dateStart.subtract(dstVariable,"minute")
                        booking.dateEnd = booking.dateEnd.subtract(dstVariable,"minute")
                    }
                }
                this.myCalendarActBooking = response;
                return response;
            })
        );
    }

    @action
    requestExtraGigFee(bookingId, extraGigFee) {
        let id = bookingId || this.id;
        this.isLoading = true;

        return agent.Booking.requestExtraGigFee(id, extraGigFee)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    approveExtraGigFee(bookingId, profileId) {
        let id = bookingId || this.id;
        this.isLoading = true;

        return agent.Booking.approveExtraGigFee(id, profileId)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    declineExtraGigFee(bookingId, profileId) {
        let id = bookingId || this.id;
        this.isLoading = true;

        return agent.Booking.declineExtraGigFee(id, profileId)
            .then(
                action(response => {
                    return response;
                })
            )
            .finally(
                action(() => {
                    this.isLoading = false;
                })
            );
    }

    @action
    getBookingTitle(booking) {
        if (booking.title) {
            return booking.title;
        } else if (booking.tempTitle) {
            return booking.tempTitle;
        } else {
            if (booking.status === BookingStatusType.Resent.ordinal) {
                return `Resent@${booking.venue ? booking.venue.profileName : booking.venueName}`;
            } else {
                return `${booking.act ? booking.act.actName : ""}@${booking.venue ? booking.venue.profileName : booking.venueName}`;
            }
        }
    }

    @action
    getBookableActs() {
        const currentProfile = profileStore.getCurrentProfile();
        const { id, dateStart, timeStart, timeEnd, actType, actLineup } = this.booking;
        var utcOffset = -(moment(dateStart).utcOffset());
        return agent.Booking.getBookableActs(
            currentProfile?.id,
            id ? id : 0,
            moment(dateStart)
                .endOf("day")
                .format("MM-DD-YYYY"),
            timeStart
                ? moment(timeStart, "HH:mm")
                    .local()
                    .format("HH:mm")
                : null,
            timeEnd
                ? moment(timeEnd, "HH:mm")
                    .local()
                    .format("HH:mm")
                : null,
            actType,
            actLineup,
            utcOffset
        ).then(
            action(response => {
                this.bookableActs = response;
                return response;
            })
        );
    }

    @action
    updateBookingsPurchaseOrder() {
        // if (this.booking && this.booking.purchaseOrderCode) {
        //     var data = {
        //         bookingIds: bookingIds,
        //         purchaseOrderCode: this.booking.purchaseOrderCode
        //     };
        //     return agent.Booking.updateBookingsPurchaseOrder(data).then(response => {
        //         response.forEach(
        //             action(data => {
        //                 this.upcomings = this.upcomings.map(x => {
        //                     if (x.id === data.id) {
        //                         x.purchaseOrderCode = data.purchaseOrderCode;
        //                     }
        //                     return x;
        //                 });
        //             })
        //         );
        //         return response;
        //     });
        // } else {
        //     return Promise.reject();
        // }
        return Promise.reject();
    }
}

export default new BookingRequestStore();
